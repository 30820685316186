import { theme } from './theme'
import { global } from 'styled-jsx/css'

const createCssVariables = (
  vars: { [key: string]: string | number },
  prefix: string,
  suffix?: string,
): string =>
  Object.entries(vars)
    .map(
      ([key, value]) => `--${prefix ? prefix + '-' : ''}${key}: ${value}${suffix ? suffix : ''};`,
    )
    .join(' ')

// For future fonts : https://developer.mozilla.org/fr/docs/Web/CSS/font-weight#correspondance_entre_les_valeurs_num%C3%A9riques_et_les_noms_commun%C3%A9ment_utilis%C3%A9s
// https://fonts.googleapis.com/css?family=Overpass:100,200,300,400,500,600,700,800,900&subset=latin
export const fontStyle = global`
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 100 900;
  src: url(/fonts/Overpass/qFdH35WCmI96Ajtm81GlU9vgwBcI.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  size-adjust: 100.63%;
  ascent-override: 102%;
  src: local(Arial);
  font-family: 'Overpass Fallback';
}
`

export const globalStyle = global`
html {
  overflow-x: hidden;
}

html,
body, #__next {
  padding: 0;
  margin: 0;
  font-family: var(--font-overpass);
}

#__next {
  position: relative;
  min-height: 100vh;
  width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0px;
}

* {
  box-sizing: border-box;
}

figure {
  margin: 0
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.flex-no-wrap {
  flex-wrap: no-wrap;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

// accessibility class for screen readers
.sr-only {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(50%);
			clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
}

:root {
  // Colors
  ${createCssVariables(theme.colors, 'color')}

  // Default theme
  ${createCssVariables(theme.default.colors, 'theme')}

  // Fonts
  ${createCssVariables(theme.fonts, 'font')}

  // margin
  ${createCssVariables(theme.block, 'gap', 'px')}

  // svg default colors
  --svg-color-primary: ${theme.colors.deepBlue};
  --svg-color-secondary: ${theme.colors.focusBlue};

  --overflow-menu: initial;
}

body[data-theme=blue] {
  ${createCssVariables(theme.blue.colors, 'theme')}
  --overflow-menu: initial;
}

body[data-theme=dark] {
  ${createCssVariables(theme.dark.colors, 'theme')}
  --overflow-menu: initial;
}

.PlaceholderLogo-dark {
  --svg-color-primary: ${theme.colors.white};
}
.PlaceholderLogo-light {
  --svg-color-primary: ${theme.colors.deepBlue};
}
`
